<template>
  <section class="invoice-preview-wrapper">

    <!-- Alert: No item found -->
    <!-- <b-alert
      variant="danger"
      :show="invoiceData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching invoice data
      </h4>
      <div class="alert-body">
        No invoice found with this invoice id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-invoice-list'}"
        >
          Invoice List
        </b-link>
        for other invoices.
      </div>
    </b-alert> -->

    <!-- <b-row
      v-if="invoiceData"
      class="invoice-preview"
    > -->
    <b-row
      class="invoice-preview"
    >

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <!-- Header -->
          <b-card-body class="invoice-padding pb-0">
            <!-- isBusy -->
            <div
              v-if="isBusy"
              class="text-center text-danger my-2"
            >
              <b-spinner class="align-middle" />
              <strong> Memuat Data...</strong>
            </div>

            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

              <!-- Header: Left Content -->
              <div>
                <div class="logo-wrapper">
                  <b-img
                    src="@/assets/images/logo/logo.png"
                    alt="logo"
                    fluid
                    class="img-fluid"
                    width="46%"
                  />
                  <!-- <logo /> -->
                  <h3 class="text-primary invoice-logo">
                    Kanal Dagang
                  </h3>
                </div>
                <p class="card-text mb-25">
                  Sopo Del Tower
                </p>
                <p class="card-text mb-25">
                  Jakarta Selatan, Setia Budi, Sopo Del Tower Lantai 22, GoWork
                </p>
                <!-- <p class="card-text mb-0">
                  +62 857 7265 5477
                </p> -->
              </div>

              <!-- Header: Right Content -->
              <div class="mt-md-0 mt-2">
                <h4 class="invoice-title">
                  Invoice
                  <span class="invoice-number">{{ userData.payment.order_id }}</span>
                </h4>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Tanggal Invoice:
                  </p>
                  <p class="invoice-date">
                    {{ new Date(userData.payment.created_at).toLocaleDateString() }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Batas kadarluarsa:
                  </p>
                  <p class="invoice-date">
                    {{ new Date(userData.profile.member_expired).toLocaleDateString() }}
                  </p>
                </div>
                <div class="invoice-date-wrapper">
                  <p class="invoice-date-title">
                    Status Pembayaran:
                  </p>
                  <p class="invoice-date">
                    {{ userData.payment.transaction_status }}
                  </p>
                </div>
              </div>
            </div>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Invoice Client & Payment Details -->
          <b-card-body
            class="invoice-padding pt-0"
          >
            <b-row class="invoice-spacing">

              <!-- Col: Invoice To -->
              <b-col
                cols="12"
                xl="6"
                class="p-0"
              >
                <h6 class="mb-2">
                  Invoice Kepada:
                </h6>
                <h6 class="mb-25">
                  {{ userData.profile.name }}
                </h6>
                <!-- <p class="card-text mb-25">
                  PT Palapa Mitra Solusi
                </p> -->
                <!-- <p class="card-text mb-25">
                  Jakarta Selatan, Setia Budi
                </p> -->
                <p class="card-text mb-25">
                  {{ userData.profile.phone }}
                </p>
                <p class="card-text mb-0">
                  {{ userData.profile.email }}
                </p>
              </b-col>

              <!-- Col: Payment Details -->
              <b-col
                xl="6"
                cols="12"
                class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
              >
                <div>
                  <h6 class="mb-2">
                    Detail Pembayaran:
                  </h6>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">
                          Total {{ userData.payment.currency }}:
                        </td>
                        <td><span class="font-weight-bold"> {{ userData.payment.gross_amount }} </span></td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          Pembayaran :
                        </td>
                        <td> {{ userData.payment.payment_type }} </td>
                      </tr>
                      <!-- <tr>
                        <td class="pr-1">
                          Country:
                        </td>
                        <td> paymentDetails.country </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          IBAN:
                        </td>
                        <td> paymentDetails.iban </td>
                      </tr>
                      <tr>
                        <td class="pr-1">
                          SWIFT code:
                        </td>
                        <td> paymentDetails.swiftCode </td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Invoice Description: Table -->
          <b-table-lite
            responsive
            :fields="['description', 'total']"
          >
            <!-- <template #cell(taskDescription)="data"> -->
            <!-- <template> -->
            <!-- <b-card-text class="font-weight-bold mb-25">
                {{ data.item.taskTitle }}
              </b-card-text>
              <b-card-text class="text-nowrap">
                {{ data.item.taskDescription }}
              </b-card-text>
            </template> -->
          </b-table-lite>

          <!-- Invoice Description: Total -->
          <b-card-body class="invoice-padding pb-0">
            <b-row>

              <!-- Col: Sales Persion -->
              <b-col
                cols="12"
                md="4"
                class="mt-md-0 mt-3"
                order="2"
                order-md="1"
              >
                <b-card-text class="mb-0">
                  <span class="font-weight-bold">Kepada :</span>
                  <span class="ml-75">{{ userData.profile.name }}</span>
                </b-card-text>
              </b-col>

              <!-- Col: Total -->
              <b-col
                cols="12"
                md="8"
                class="mt-md-8 d-flex justify-content-end"
                order="1"
                order-md="2"
              >
                <div class="invoice-total-wrapper">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Paket Pilihan :
                    </p>
                    <p class="invoice-total-amount">
                      {{ userData.payment.order_id.split("-", 1)[0] }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Harga Paket :
                    </p>
                    <p class="invoice-total-amount">
                      {{ userData.payment.gross_amount / Math.abs(userData.payment.order_id.substr(userData.payment.order_id.length -2, 2)) }}
                    </p>
                  </div>
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Jumlah Bulan :
                    </p>
                    <p class="invoice-total-amount">
                      {{ Math.abs(userData.payment.order_id.substr(userData.payment.order_id.length -2, 2)) }}
                    </p>
                  </div>
                  <hr class="my-50">
                  <div class="invoice-total-item">
                    <p class="invoice-total-title">
                      Total:
                    </p>
                    <p class="invoice-total-amount">
                      {{ userData.payment.gross_amount }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Spacer -->
          <hr class="invoice-spacing">

          <!-- Note -->
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">Note: </span>
            <span>Mohon perhatikan kembali total harga dan rincian pembayaran anda. Terima kasih</span>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions"
      >
        <b-card>

          <!-- Button: Send Invoice -->
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            v-b-toggle.sidebar-send-invoice
            variant="primary"
            class="mb-75"
            block
          >
            Send Invoice
          </b-button> -->

          <!-- Button: DOwnload -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
          >
            Download
          </b-button> -->

          <!-- Button: Print -->
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            @click="printInvoice"
          >
            Print
          </b-button>

          <!-- Button: Edit -->
          <!-- <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            class="mb-75"
            block
            :to="{ name: 'apps-invoice-edit', params: { id: $route.params.id } }"
          >
            Edit
          </b-button> -->

          <!-- Button: Add Payment -->
          <b-button
            v-b-toggle.sidebar-invoice-add-payment
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-75"
            block
          >
            Lanjutkan
          </b-button>
        </b-card>
      </b-col>
    </b-row>
    <!-- sent /> -->
  </section>
</template>

<script>
import {
  defineComponent, ref, onMounted, reactive, computed,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
// import router from '@/router'
import Swal from 'sweetalert2'
import {
  BRow, BCol, BCard, BCardBody, BSpinner, BTableLite, BCardText, BButton, VBToggle, BImg,
} from 'bootstrap-vue'
// import Logo from '@core/layouts/components/Logo.vue'
import { $themeConfig } from '@themeConfig'
import Ripple from 'vue-ripple-directive'
import useJwt from '@/auth/jwt/useJwt'
// import decodeJwt from '@/helper/jwtAuth'
// import { initialAbility } from '@/libs/acl/config'
// import invoiceStoreModule from '../invoiceStoreModule'
// import InvoiceSidebarSendInvoice from '../InvoiceSidebarSendInvoice.vue'
// import InvoiceSidebarAddPayment from '../InvoiceSidebarAddPayment.vue'

export default defineComponent({
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableLite,
    BCardText,
    BButton,
    BImg,
    BSpinner,
    // BAlert,
    // BLink,

    // Logo,
    // InvoiceSidebarAddPayment,
    // InvoiceSidebarSendInvoice,
  },
  setup(_, { root }) {
    const isBusy = ref(false)

    const userData = reactive({
      profile: computed(() => {
        isBusy.value = false
        return store.state.user.user
      }),
      payment: computed(() => {
        isBusy.value = false
        return store.state.packet.payment
      }),
    })

    const getPaymentDetail = async id => {
      isBusy.value = true
      store.dispatch('packet/getPaymentById', id).then(() => {
        isBusy.value = false

        // ask to logout if member type not updated yet
        // const token = localStorage.getItem('accessToken')
        const decoded = 'decodeJwt(token)'
        if (decoded.member_type !== userData.profile.member_type) {
          Swal.fire({
            title: 'Cek Perubahan',
            text: `Pembelian dengan invoice no #${userData.payment.order_id} telah berhasil. Logout sekarang untuk melihat perubahan ?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Ya, Logout sekarang!',
            cancelButtonText: 'Tidak, Nanti saja',
          })
            .then(result => {
              if (result.isConfirmed) {
              // Remove userData from localStorage
              // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
                localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
                localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
                localStorage.removeItem('userData')
                localStorage.removeItem('accessToken')
                localStorage.removeItem('refreshToken')

                // // Reset ability
                // userData.profile.ability.update(initialAbility)

                // Redirect to login page
                root.$router.push({ name: 'auth-login' })
              // console.log(store.state.admin.userRequest, 'get si store state on componennt')
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                Swal.fire(
                  'Dibatalkan',
                  'Segera dilakuin lagi ya. Jangan ditunda :)',
                  'warning',
                )
              }
            })
        }
      })
    }

    onMounted(() => {
      // isBusy.value = true
      // get id from url without vue router
      const { query } = root.$route

      if (query) {
        if (query.id) {
          getPaymentDetail(query.id)
        } else if (query.order_id) {
          getPaymentDetail(query.order_id)
        }
      }
      // const id = query.id
      // const status = query.status

      // const urls = window.location.href.split('?')
      // if (urls.length === 2) {
      //   const splitUrls = urls[1].split('&')
      //   const ids = {}
      //   let separatedIds = ''
      //   splitUrls.forEach(id => {
      //     separatedIds = id.split('=')
      //     if (separatedIds.length === 2) {
      //       // eslint-disable-next-line prefer-destructuring
      //       ids[separatedIds[0]] = separatedIds[1]
      //     }
      //   })
      //   isBusy.value = true
      //   store.dispatch('packet/getPaymentById', ids.id).then(() => {
      //     isBusy.value = false
      //   })
      // }
    })

    // const invoiceData = ref(null)
    // const paymentDetails = ref({})

    // // Invoice Description
    // // ? Your real data will contain this information
    // const invoiceDescription = [
    //   {
    //     taskTitle: 'Native App Development',
    //     taskDescription: 'Developed a full stack native app using React Native, Bootstrap & Python',
    //     total: '$1,800.00',
    //   },
    //   {
    //     taskTitle: 'UI Kit Design',
    //     taskDescription: 'Designed a UI kit for native app using Sketch, Figma & Adobe XD',
    //     total: '$1200.00',
    //   },
    // ]

    // const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // // Register module
    // if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    // })

    // store.dispatch('app-invoice/fetchInvoice', { id: router.currentRoute.params.id })
    //   .then(response => {
    //     invoiceData.value = response.data.invoice
    //     paymentDetails.value = response.data.paymentDetails
    //   })
    //   .catch(error => {
    //     if (error.response.status === 404) {
    //       invoiceData.value = undefined
    //     }
    //   })

    const printInvoice = () => {
      window.print()
    }

    const { appLogoImage } = $themeConfig.app

    return {
      // invoiceData,
      // paymentDetails,
      // invoiceDescription,
      printInvoice,
      appLogoImage,
      isBusy,
      userData,
      getPaymentDetail,
    }
  },
})
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
              > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
